/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

@media screen and (min-device-width: 300px) and (max-device-width: 400px) {
  // .ant-table-thead > tr > th {
  //   font-size: 10px !important;
  // }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 6px !important;
  }
}

// @media screen and (max-width: 400px) {
//   body {
//     font-size: 1em;
//   }
//   .ant-table-thead>tr>th {
//     font-size: 10px;
// }

// }
.header {
  // text-align: center;
  position: fixed;
  width: 100%;
  z-index: 2;
  color: #ffffff !important;

  .logo {
    color: #ffffff !important;
    font-size: 32px;
    display: flex !important;
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  .logged-in-user {
    //color: #ffffff;
    // float: right;
    //font-size: 16px;
    //line-height: 1.5;
    //padding: 20px 0;
    //cursor: pointer;
    //&:hover {
    //  color: #1890ff;
    //}
    // width: 40%;
  }
}

.ant-layout {
  min-height: 100vh !important;
  max-height: 100% !important;
}
@media screen and (min-device-width: 300px) and (max-device-width: 400px) {
  .logged-in-user {
    width: 160px;
  }
  .ant-space {
    display: initial !important;
  }
  .ant-layout,
  .ant-layout * {
    box-sizing: border-box;
    //overflow: auto !important; // TODO: Ask nilesh why added overflow and see if we can add specificity.
  }
  .ant-table * {
    overflow: auto !important;
  }
  .ant-btn > span {
    display: inline-flex !important;
  }
  .ant-descriptions-bordered .ant-descriptions-item-content,
  .ant-descriptions-bordered .ant-descriptions-item-label {
    padding: 8px 10px !important;
    border-right: 1px solid #f0f0f0 !important;
  }
  //   .ant-layout {
  //     min-height: 100vh !important;
  //     max-height: 100% !important;

  // }

  // .main-page {
  //     min-height: 100vh !important;
  //     max-height: 100% !important;
  // }
  .main-page {
    padding-top: 65px !important;
  }
  .logotext {
    color: #fff;
    //font-size: 24px;
    img {
      height: 40px !important;
    }
  }
}

.allow-notification-cta {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

@media (min-width: 992px) {
  .logged-in-user {
    width: 100%;
  }
}

.content {
  padding: 1rem;
  height: 100vh;
}
.login-container {
  width: 100%;
  height: 100%;
  margin: 24px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-page {
  padding-top: 75px;
}

.logotext {
  color: #fff;
  //font-size: 24px;
  img {
    height: 50px;
  }
}
