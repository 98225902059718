.mega-input {
  width: 80%;
  height: 60px;
  border-radius: 8px;
  font-size: 20px;
  &:focus {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }
}
.on-scan-demo-container {
  @media only screen and (max-device-width: 480px) {
    display: none;
  }
}
