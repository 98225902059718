.upload-wrapper--info-msg {
  display: inline-block !important;
  margin-bottom: 10px;
}
.navbar {
  //background-color: #001529;
  background-color: #000 !important;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  position: fixed;
  z-index: 10;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  width: 100%;
}

@media (min-width: 300px) {
  .navbar {
    background-color: #001529;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    position: fixed;
    z-index: 10;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .menu {
    display: none;
  }
}

.logo {
  height: 32px;
  margin-left: 1rem;
}
//.logotext {
//  color: #fff;
//  font-size: 24px;
//  img {
//    height: 50px;
//  }
//}
